import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Radio buttons`}</em>{` are used when a list of two or more options are mutually exclusive, meaning the user must select only one option.`}</p>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <h4 {...{
      "id": "headings"
    }}>{`Headings`}</h4>
    <p>{`If necessary, a heading can accompany a set of radio buttons to provide further clarity for the user. Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized).`}</p>
    <h4 {...{
      "id": "labels"
    }}>{`Labels`}</h4>
    <p>{`Always use a clear and concise label for radio buttons. Be explicit about the action that will follow if the radio button is selected. Labels appear to the right of radio buttons. Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized) and no more than three words.`}</p>
    <h2 {...{
      "id": "default-selection"
    }}>{`Default selection`}</h2>
    <p>{`A set of radio buttons should default to having one option selected. Never display them without a default selection.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      